.header {
    position: fixed;
    width: 100vw;
    max-height: 90px !important;
    min-height: 90px !important;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 16px 0 0 24px;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000038;
    z-index: 9999;
}

.header .logo-container {
    min-height: 20px;
    min-width: 233px;
    max-height: 20px;
    max-width: 233px;
    position: relative;
    background-image: url("/public/logo.svg");
    background-repeat: no-repeat;
    background-size: 233px 50px;
    padding: 37px 0 0 0;
    display: inline-block;
}

.header a {
    text-decoration: none;
    color: #fff;
    text-align: right;
    font-size: 22.5px;
    max-width: 233px;
}

.header .queries {
    margin: 14px 23px;
    vertical-align: middle;
    float: right;
    padding: 0 !important;
    color: #9bb0bb;
}

.header .queries select {
    margin: 0 !important;
    height: 30px;
    width: 300px;
    color: #9bb0bb;
    background-color: #000;
    font-size: 1em;
    vertical-align: middle;
}

.header .queries select option {
    color: #9bb0bb;
    background-color: #000;
}

.header .queries button {
    border-width: .5px;
    border-color: #9bb0bb;
    margin: 0 !important;
    padding: 0 10px;
    height: 30px;
    color: #fff;
    background: var(--bgcolor);
}

.sidebar {
    position: fixed;
    top: 90px;
    left: 0;
    width: 240px;
    height: calc(100vh - 90px);
    background-color: #fff;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    /* z-index: 1; */
    padding: 40px 0;
}

.nav-item {
    margin-left: 30px;
    margin-top: 20px;
    font-size: 1.2em;
    font-weight: 500;
    min-height: 40px;
    padding-top: 10px;
    vertical-align: middle;
    opacity: .1;
    cursor: default;
}

.nav-item.enabled {
    opacity: 1;
    cursor: pointer;
}

.nav-item.enabled .nav-icon {
    opacity: .3;
}

.nav-item.selected {
    font-weight: 700;
}

.nav-item.selected .nav-icon {
    opacity: 1;
}

.nav-icon {
    min-width: 50px;
    min-height: 45px;
    max-width: 50px;
    max-height: 45px;
    /* display: inline-block; */
    margin-top: -11px;
    float: left;
    background-repeat: no-repeat;
}

.nav-icon.choose-file {
    background-image: url("/public/choose-file.svg");
}

.nav-icon.configure {
    margin-top: -6px;
    background-image: url("/public/configure.svg");
}

.nav-icon.overview {
    background-image: url("/public/overview.svg");
}

.nav-icon.detailed-analysis {
    background-image: url("/public/detailed-analysis.svg");
    margin-left: -5px;
    margin-right: 5px;
}

.footer {
    color: var(--muted);
    font-size: .9em;
    bottom: 0;
    position: absolute;
    width: 100%;
    text-align: center;
}

.footer *:link {
    color: var(--muted);
}

.footer *:visited {
    color: var(--muted);
}