.donut-wrapper {
    position: relative;
    width: 450px;
    padding: 30px;
    display: inline-block;
    margin: 0;
    box-sizing: border-box;
}

.donut {
    width: 240px;
    height: 240px;
    border-radius: 50%;
    position: relative;
}

.hole {
    position: absolute;
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background: #fff;
    top: 60px;
    left: 60px;
}

.donut .caption {
    position: absolute;
    color: #133349;
    top: calc(50% - 20px);
    max-width: 40%;
    left: 30%;
}

.donut {
    display: inline-block;
    align-items: center;
    justify-content: center;
}

.marker-anchor {
    position: absolute;
    height: 10px;
    right: 70px;
    border-top: 1px solid #666;
    z-index: 999;
    margin-left: 10px;
    text-align: right;
    font-weight: 600;
}

.marker-anchor:before {
    content: '';
    position: absolute;
    width: 15px;
    height: 3px;
    border-top: 1px solid #666;
    border-left: 4px solid #666;
    transform: rotate(-45deg);
    transform-origin: 0 0;
    left: -13.5px;
    top: 12.5px;
}