:root {
    --optimized: #133349;
    --red: #c42930;
}

.results {
    padding-top: 50px;
    box-sizing: border-box;
    width: 1020px;
    margin: 0 auto;
    user-select: none;
}

.results .link {
    text-decoration: underline;
    cursor: po;
}

.results {
    padding-top: 50px;
    box-sizing: border-box;
    width: 1000px;
    margin: 0 auto;
    user-select: none;
}

.results .lane-caption {
    width: 70px;
    margin-top: 8px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: top;
    color: #133349;
    text-align: right;
    font-size: 14px;
    font-weight: bold;
}

.results .bars {
    width: 920px;
    display: inline-block;
}

.results .bars.wide {
    width: 1000px;
}

.results h1 {
    text-align: left;
    margin-bottom: 50px;
    text-transform: uppercase;
    font-weight: 700;
}

.results h1.title {
    color: #797979;
}

.results h1 .speedup {
    color: #133349;
    font-size: 1.25em;
}

.results h1 .bad-time {
    color: var(--red);
}

.results h1 .good-time {
    color: #133349;
}

.improvement {
    position: relative;
    box-sizing: border-box;
    margin: 0px;
    padding: 5px !important;
    width: 100%;
    height: 40px;
    color: #000;
    vertical-align: middle;
}

.improvement .bg {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../public/improved-bg.png");
    animation: mymove 20s infinite;
    animation-timing-function: linear;
    background-position: top right;
    background-size: 10px;
    opacity: .3;
}

@keyframes mymove {
    100% {
        background-position: top left;
    }
}


.improvement img {
    margin: 0 !important;
    filter: drop-shadow(1px 1px .5px #fff) drop-shadow(-1px -1px .5px #fff) drop-shadow(-1px 1px .5px #fff) drop-shadow(1px -1px .5px #fff);
    vertical-align: middle;
}

.improvement .caption-text {
    margin: 0 !important;
    vertical-align: middle;
    display: inline-block;
    font-size: 18px;
    filter: drop-shadow(1px 1px .5px #fff) drop-shadow(-1px -1px .5px #fff) drop-shadow(-1px 1px .5px #fff) drop-shadow(1px -1px .5px #fff);
}

.legend {
    margin-bottom: 20px;
    float: right;
    text-align: right;
}

.legend div {
    display: inline-block;
}

.legend .legend-color {
    margin-left: 20px;
    min-height: 10px;
    min-width: 10px;
    max-height: 10px;
    max-width: 10px;
    vertical-align: middle;
    margin-bottom: 2px;
}

.legend .legend-text {
    margin-left: 10px;
    font-weight: 600;
}

.details {
    margin-top: 100px;
    height: 50vh;
    width: 100vw;
    max-height: 50vh;
    max-width: 100vw;
    background-color: #07141c;
    overflow: scroll;
    border-top: 2px solid #07141c
}

.details .details-content {
    width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.details .close {
    float: right;
    cursor: pointer;
    font-size: 3em;
    vertical-align: top;
    margin-top: -.2em;
}

.results .link {
    text-decoration: underline;
    cursor: po;
}

.results .bars {
    width: 920px;
    display: inline-block;
}

.results .bars.accordion {
    overflow: hidden;
    position: relative;
    transition: max-height 1s ease, min-height 1s ease;
}

.accordion-toggle {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: linear-gradient(180deg, rgba(248, 248, 248, 0) 0%, rgba(248, 248, 248, 1) 50%);
    text-align: center;
    border-left: 1px solid #9bb0bb;
}

.accordion-toggle .switch {
    border: 1px solid #9bb0bb;
    border-radius: 10px;
    width: 20px;
    height: 20px;
    margin-top: 10px;
    background: #f8f8f8;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, .2);
}

.accordion-toggle .switch.baseline:hover {
    background: var(--red)
}

.accordion-toggle .switch.compared:hover {
    background: var(--optimized)
}

.accordion-toggle .switch .arrow {
    height: 8px;
    width: 8px;
    border-bottom: 1px solid #9bb0bb;
    border-right: 1px solid #9bb0bb;
}

.accordion-toggle .switch .arrow.down {
    transform: rotate(45deg);
    transform-origin: 2px 13px;
}

.accordion-toggle .switch .arrow.up {
    transform: rotate(225deg);
    transform-origin: 9px 8px;
}

.results h2 {
    margin-top: 20px;
    text-align: left;
}

.improvement {
    position: relative;
    box-sizing: border-box;
    margin: 0px;
    padding: 5px !important;
    width: 100%;
    height: 40px;
    color: #000;
    vertical-align: middle;
}

.improvement .bg {
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url("../public/improved-bg.png");
    animation: mymove 20s infinite;
    animation-timing-function: linear;
    background-position: top right;
    background-size: 10px;
    opacity: .3;
}

@keyframes mymove {
    100% {
        background-position: top left;
    }
}


.improvement img {
    margin: 0 !important;
    filter: drop-shadow(1px 1px .5px #fff) drop-shadow(-1px -1px .5px #fff) drop-shadow(-1px 1px .5px #fff) drop-shadow(1px -1px .5px #fff);
    vertical-align: middle;
}

.improvement .caption-text {
    margin: 0 !important;
    vertical-align: middle;
    display: inline-block;
    font-size: 18px;
    filter: drop-shadow(1px 1px .5px #fff) drop-shadow(-1px -1px .5px #fff) drop-shadow(-1px 1px .5px #fff) drop-shadow(1px -1px .5px #fff);
}

.details {
    margin-top: 100px;
    height: 50vh;
    width: 100vw;
    max-height: 50vh;
    max-width: 100vw;
    background-color: #07141c;
    overflow: scroll;
    border-top: 2px solid #07141c
}

.details .details-content {
    width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
    padding-bottom: 50px;
}

.details .close {
    float: right;
    cursor: pointer;
    font-size: 3em;
    vertical-align: top;
    margin-top: -.2em;
}

table {
    margin-top: 15px;
}

h3 {
    margin-top: 20px;
}

.nav {
    position: fixed;
    right: 0;
    top: 103px;
    padding: 10px;
    background: var(--bgcolor);
    box-shadow: -2px 2px 3px var(--bgcolor);
    z-index: 999999999;
}

.nav-item.selected {
    font-weight: 600;
}

table.stats {
    margin-bottom: 50px;
}

table.stats.two-cols td,
table.stats.two-cols th {
    width: 50%;
}

table.stats.three-cols td,
table.stats.three-cols th {
    width: calc(100% / 3);
}

table.metrics tr.indent td {
    padding: 10px;
}

table.metrics tr.indent-1 td {
    font-size: 1.1em;
}

table.metrics tr.indent-2 td {
    font-size: 1em;
}

table.metrics td.indent {
    display: inline-block;
    /* border-bottom: none; */
    border-right: none;
    padding: 10px;
    top: 1px;
    position: relative;
}

table.metrics td.indent:last-of-type {
    border-bottom: none !important;
}

table.metrics td.indent.indent-1 {
    width: calc(100% - 50px);
    margin-left: 50px;
}

table.metrics td.indent.indent-2 {
    width: calc(100% - 100px);
    margin-left: 100px;
}

.detailed-nav-item {
    display: inline-block;
    width: 32%;
    text-transform: uppercase;
    font-weight: 700;
    margin-bottom: 40px;
    color: var(--muted);
    cursor: pointer;
    font-size: 24px;
}

.results select {
    height: 30px;
    color: #094531;
    background-color: #bebebe50;
    font-size: 1em;
    vertical-align: middle;
    border-radius: 20px;
    text-align: left;
    border: #094531 solid 1px;
    width: 100%;
    padding: 0 10px;
}

.detailed-nav-item .number {
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 18px;
    border: 1px solid var(--muted);
    text-align: center;
}

.detailed-nav-item.active {
    color: #133349;
}

.detailed-nav-item.active .number {
    border: 1px solid #133349;
    color: #133349;
}

.info {
    min-width: 20px;
    max-width: 20px;
    min-height: 20px;
    max-height: 20px;
    vertical-align: middle;
    display: inline-block;
    border-radius: 12px;
    border: 1px solid var(--muted);
    text-align: center;
    color: var(--muted);
    cursor: pointer;
}

.info.static {
    color: #fff;
    border-color: #fff;
}

.info:not(.static):hover {
    color: #133349;
    border: 1px solid #133349;
}

.task-tabs h3 {
    display: inline-block;
    text-align: center;
}

.has-hidden-stages {
    animation: fadeInPartial 2s;
    animation-iteration-count: 1;
    top: 0;
    left: 0;
    position: absolute;
    min-height: 60px;
    background: url("../public/improved-bg.png");
    background-size: 10px;
    opacity: .3;
    z-index: 999;
}

.hidden-stages-caption {
    animation: fadeIn 2s;
    animation-iteration-count: 1;
    top: 0;
    left: 0;
    position: absolute;
    margin-left: 10px;
    margin-top: 2px;
    border-left: 1px solid #133349;
    padding-left: 10px;
    max-width: 100px;
    white-space: nowrap;
}

.hidden-stages-caption-handle {
    animation: fadeIn 2s;
    animation-iteration-count: 1;
    top: 50%;
    left: 0;
    border-top: 1px solid #133349;
    position: absolute;
}

.hidden-stages-caption-handle:before {
    animation: fadeIn 2s;
    animation-iteration-count: 1;
    background: #133349;
    max-width: 4px;
    max-height: 4px;
    min-width: 4px;
    min-height: 4px;
    content: " ";
    border: 1px solid #133349;
    overflow: hidden;
    position: absolute;
    top: -3.5px;
    left: -4px;
    border-radius: 4px;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

@keyframes fadeInPartial {
    0% {
        opacity: 0;
    }

    100% {
        opacity: .3;
    }
}

.not-accelerated {
    background: var(--muted);
    color: #fff;
    padding: 5px;
    border-radius: 20px;
    font-size: 1em;
    margin-top: 20px;
    margin-left: 80px;
    padding: 10px 20px;
}