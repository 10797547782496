:root {
    --lane-width: 910px;
    --lane-height: 60px;
    --alt-lane: #fff;
}

.stacked-bars:not(.axis) * {
    pointer-events: none;
}

.stacked-bars {
    width: var(--lane-width);
    user-select: none;
    position: relative;
    background-color: var(--bgcolor);
    vertical-align: top;
}

.stacked-bars.lane:not(.inner) {
    width: calc(var(--lane-width) - 1px);
    height: var(--lane-height);
    border-left: 1px solid var(--muted);
    white-space: nowrap;
    overflow: visible;
}

.stacked-bars.axis:not(.inner) {
    border-top: 1px solid var(--muted);
}

.stacked-bars.axis.inner {
    display: none;
}

.stacked-bars.inner {
    padding-top: 0 !important;
    height: calc(var(--lane-height) / 4 * 3);
    width: 100%
}

.stacked-bars.inner .bar {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 0 !important;
}

.bar,
.placeholder {
    box-sizing: border-box;
    display: inline-block;
    margin-top: calc(var(--lane-height) / 6);
    margin-bottom: calc(var(--lane-height) / 6);
    padding: 0;
    height: calc(var(--lane-height) / 6 * 4);
    vertical-align: top;
    text-align: center;
    background-color: yellow;
    transition: width 1s ease-in-out;
    border-radius: 5px;
}

.bar.gaps:not(:first-child) {
    margin-left: 5px;
}

.bar.gaps:not(:last-child) {
    margin-right: 5px;
}

/* .bar :not(.stacked-bars) {
    margin-top: 15px;
} */

.gap {
    display: inline-block;
    padding: 0;
    margin-top: 0;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    /* background-color: blue; */
}

.gap-inner {
    height: 100%;
    border-left: 2px solid #9bb0bb;
    margin-left: calc(50% - 1px);
}

.tick {
    text-align: right;
    display: inline-block;
    width: 10%;
    vertical-align: top;
    white-space: nowrap;
}

.mark {
    min-height: 10px;
    border-left: 1px solid #9bb0bb;
}

.tick:not(.floating):nth-child(9)>.mark {
    border-right: 1px solid #9bb0bb !important;
}

.tick.floating {
    position: absolute;
    width: 0;
    transition: left 1s ease-in-out;
}

.tick.floating .mark {
    min-height: 30px;
}

.stacked-bars .timestamp.scale-marker {
    border-radius: 30px;
    border: 1px solid #9bb0bb;
    min-height: 60px;
    min-width: 60px;
    max-height: 60px;
    max-width: 60px;
    text-align: center;
    display: inline-block;
    margin-left: -30px;
    color: #fff;
    font-weight: 600;
}

.stacked-bars .timestamp.scale-marker .timestamp-inner {
    margin-top: 20px;
}

.stacked-bars .timestamp.scale-marker {
    background-color: #cc3333;
}

.stacked-bars .timestamp.scale-marker.alt {
    background-color: #133349;
}

.stacked-bars .scale-container {
    height: 0;
    transition: width 1s ease-in-out, opacity 1s ease-in-out;
}

.edge {
    background-color: var(--bgcolor) !important;
    border-left-width: 1px;
    border-right-width: 1px;
    border-style: solid;
    border-color: var(--muted);
}

.bars-wrapper {
    overflow: visible;
}

.bars-wrapper:after {
    opacity: 0;
    content: "";
    position: absolute;
    left: 100%;
    width: 50000px;
    height: 100%;
    background: linear-gradient(to right, rgba(248, 248, 248, 0), rgba(248, 248, 248, 1) 60px, rgba(248, 248, 248, 1) 100%);
    transition: opacity 1s ease-in-out;
}

.bars-wrapper.alt:after {
    opacity: 1;
}

.edge.edge-top {
    border-top-width: 1px;
    border-bottom-width: 0;
    margin-bottom: 0;
    margin-bottom: 0;
    height: calc(var(--lane-height) / 6 * 5);
}

.edge.edge-mid {
    border-top-width: 0;
    border-bottom-width: 0;
    margin-top: 0;
    margin-bottom: 0;
    height: calc(var(--lane-height));
}

.edge.edge-bottom {
    border-top-width: 0;
    border-bottom-width: 1px;
    height: calc(var(--lane-height) / 6 * 5);
    margin-top: 0;
}

.edge.edge-left {
    margin-left: 5px;
    margin-right: 0 !important;
}

.edge.edge-right {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.alt-lane {
    background-color: var(--alt-lane);
}

.caption-wrapper {
    margin-top: 0 !important;
    margin: "0 50%";
    position: relative;
    max-height: 0;
    width: 100%;
    text-align: center;
    overflow: visible;
}

.caption {
    padding-top: 3px;
    margin-top: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    vertical-align: middle;
    text-align: center;
    color: #000;
    font-weight: 600;
}

div.white-caption .caption {
    color: #fff;
    text-shadow: 1px 1px 3px black;
}