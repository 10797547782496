@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

:root {
    --bgcolor: #f8f8f8;
    --muted: #9bb0bb;
}

.content {
    margin-top: 90px;
    margin-left: 240px;
}

a {
    color: #133349;
}

body {
    background: var(--bgcolor);
    color: #133349;
    overflow-x: hidden;
}

body * {
    margin: 0;
    padding: 0;
    font-family: 'Rajdhani', sans-serif;
}

body h1 {
    font-size: 1.5em;
}

body h2 {
    font-size: 1.4em;
}

.loading {
    margin: 180px auto 0 240px;
    text-align: center;
}

.loading div.improvement {
    margin: 50px auto 0 auto;
    padding: 0 !important;
    width: 1000px;
    max-height: 7px;
    overflow: hidden;
    border: 1px solid var(--muted);
    border-radius: 1px;
    text-align: center;
}

.loading button {
    background: #cc3333;
    color: #fff;
    font-size: 20px;
    border-width: .5px;
    padding: 5px 20px;
    margin-top: 45px;
    border-radius: 18px;
    display: inline-block;
    font-weight: 600;
}

.switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 18px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--muted);
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: var(--alt-lane);
}

input:focus+.slider {
    box-shadow: 0 0 1px #2196F3;
}

input:checked+.slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 24px;
}

.slider.round:before {
    border-radius: 50%;
}


table {
    width: 100%;
}

table {
    margin-top: 50px;
    border-collapse: collapse;
}

table th {
    background-color: none;
    width: 20%;
    padding: 10px 5px;
    text-align: left;
    font-size: 1.3em;
}

table td {
    text-align: left;
    padding: 10px 5px;
    font-size: 1.2em;
    font-weight: 700;
    color: #4d4d4d;
}

table tr.odd td {
    background-color: #fff;
}

.password {
    margin: 50px auto 0 auto;
    width: 200px;
    padding: 10px;
    /* border: 2px solid var(--muted); */
    border-radius: 5px;
    margin-bottom: 10px;
    text-align: center;
}

.password input {
    margin: 20px;
    width: 100px;
    border: none;
    background: #000;
    outline: 1px solid var(--muted);
    color: #fff;
    font-size: 1.2em;
}

.toggle-switch {
    height: 30px;
    color: #094531;
    background-color: #bebebe50;
    font-size: 1em;
    vertical-align: middle;
    border-radius: 20px;
    text-align: left;
    border: #094531 solid 1px;
    padding: 0;
}

.toggle-switch.check {
    width: 40px;
    height: 20px;
    display: inline-block;
    margin-right: 10px;
    margin-left: 10px;
    user-select: none;
}

.toggle-switch.check .toggle-item.selected {
    background: #bebebe;
}

.toggle-switch.check .toggle-item.selected.checked {
    background: #133349;
}

.toggle-switch .toggle-item {
    display: inline-block;
    height: 100%;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
}

.toggle-switch .toggle-item.selected {
    color: #fff;
    background-color: #133349;
    border-radius: 20px;
}

.modal {
    z-index: 999999999;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 90px);
    text-align: left;
}

.modal .bg {
    position: fixed;
    top: 90px;
    left: 0;
    width: 100vw;
    height: calc(100vh - 90px);
    background: #00000050;
}

.modal .modal-content {
    position: fixed;
    background: #fff;
    border-radius: 5px;
    padding: 40px;
    overflow: auto;
    margin-top: 100px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 600px;
    text-align: left;
    max-width: 600px;
    left: calc(50vw - 300px);
}

.modal .modal-content h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.modal button {
    background: #cc3333;
    color: #fff;
    font-size: 20px;
    border-width: .5px;
    padding: 5px 20px;
    margin-top: 45px;
    border-radius: 18px;
    display: inline-block;
    font-weight: 500;
    float: right;
}

.modal input {
    margin: 5px 0 !important;
    height: 30px;
    color: #133349;
    background-color: #fff;
    font-size: 1em;
    vertical-align: middle;
    /* border-radius: 20px; */
    padding: 0 10px;
    border: #094531 solid 1px;
    width: 100%;
    font-weight: 600;
}

.modal textarea {
    margin: 5px 0 !important;
    height: 60px;
    color: #133349;
    background-color: #fff;
    font-size: 1em;
    vertical-align: top;
    /* border-radius: 20px; */
    padding: 0 10px;
    border: #094531 solid 1px;
    width: 100%;
    font-weight: 600;
    resize: none;
}

.request-access-link {
    float: right;
    text-decoration: underline;
    cursor: pointer;
}

.request-access button {
    margin-right: -80px;
}

.sign-in {
    margin: 0 50px;
}

.request-access {
    margin: 0 50px;
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 150px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    bottom: 120%;
    left: 50%;
    margin-left: -75px;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--tooltip-color) transparent transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

input[type="range"]::-webkit-slider-runnable-track {
    height: 16px;
    background: #ccc;
    border-radius: 16px;
}


input[type="range"]::-webkit-slider-thumb {
    background-color: #000 !important;
    border: #000 !important;

}

.comparison {
    position: relative;
}

.comparison .difference {
    position: absolute;
    border-top: 1px dotted var(--muted);
    border-right: 1px dotted var(--muted);
    border-bottom: 1px dotted var(--muted);
    width: 12px;
}

.comparison .difference .difference-inner {
    margin-left: 12px;
    transform: rotate(90deg);
    transform-origin: 0 0;
    text-align: center;
    font-style: italic;
}