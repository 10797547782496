.cluster-configuration {
    margin: 150px auto;
    padding: 0 20px;
    width: 1000px;
    text-align: center;
}

.cluster-configuration .main {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, .1);
    padding: 40px;
    text-align: left;
    box-sizing: border-box;
    font-weight: 600;
}

.cluster-configuration h1 {
    text-transform: uppercase;
}

.main.query-select {
    width: 1000px;
}

.main.query-select div {
    font-size: 14px;
}

.main.query-select th {
    cursor: pointer;
    font-size: 14px;
}

.main.query-select th .sort-arrow {
    color: var(--muted);
}

.main.query-select table {
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.main.query-select table td {
    padding: 0;
    box-sizing: border-box;
    vertical-align: top;
    border: 1px solid #ddd;
    overflow: hidden;
    line-break: wrap;
    height: 60px;
}

.main.query-select table th {
    vertical-align: bottom;
}

.main.query-select thead tr {
    position: sticky;
    top: 0;
    background-color: #fff;
    border-bottom: 1px solid #ddd;
}

.main.query-select table td div {
    box-sizing: border-box;
    padding: 10px;
    max-height: 60px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.main.query-select table .info:hover {
    background-color: #fff;
    color: #133349;
}

.main.query-select table .info:hover div {
    color: #133349;
}

.main.query-select tbody tr:hover {
    background-color: #133349;
}

.main.query-select tbody tr {
    cursor: pointer;
}

.main.query-select table tr:hover div {
    color: #fff;
}

.main.query-select table tr {
    height: 60px;
}

.main.env-config {
    margin-top: 40px;
    display: inline-block;
    width: 480px;
    height: 520px;
    vertical-align: top;
}

.main.env-config button {
    margin-left: 80px;
}

.main.env-config:first {
    margin-right: 40px;
}

.cluster-configuration select,
.cluster-configuration input {
    margin: 0 !important;
    height: 30px;
    color: #094531;
    background-color: #bebebe50;
    font-size: 1em;
    vertical-align: middle;
    border-radius: 20px;
    padding: 0 10px;
    border: #094531 solid 1px;
}

.cluster-configuration select {
    width: 700px;
    text-align: left;
}

.cluster-configuration input {
    width: 65px;
    text-align: center;
}

.cluster-configuration .input-container {
    margin-top: 15px;
}

.cluster-configuration .input-container:first-of-type {
    margin-top: 30px;
}

.cluster-configuration .input-container div.form-label {
    display: inline-block;
    min-height: 30px;
    vertical-align: middle;
    line-height: 30px;
}

.cluster-configuration .input-container .tooltiptext {
    width: 300px;
    left: -75px;
    padding: 10px;
    background-color: #aaa;
    --tooltip-color: #aaa;
}

.cluster-configuration .input-container input {
    float: right;
}

.cluster-configuration button {
    background: #cc3333;
    color: #fff;
    font-size: 20px;
    border-width: .5px;
    padding: 5px 20px;
    margin-top: 45px;
    border-radius: 18px;
    display: inline-block;
    font-weight: 600;
}

span.disabled {
    opacity: .5;
    pointer-events: none;
}

span.disabled-mini *:not(.info, .input-container) {
    pointer-events: none;
}

code {
    font-family: monospace;
}

select.small {
    width: 200px;
    float: right;
}

.toggle-switch.check {
    float: right;
    margin-right: 20px;
}

.input-container select.narrow {
    max-width: 87px;
    float: right;
}