.upload {
    margin-top: 150px;
    text-align: center;
}

.upload h1 {
    text-align: left;
    margin-left: 180px;
    margin-top: 40px;
    text-transform: uppercase;
    font-size: 2em;
}

.upload h2 {
    text-align: left;
    margin-left: 180px;
    margin-top: 40px;
    margin-bottom: 40px;
    text-transform: uppercase;
    font-size: 1.6em;
}

.demo-files {
    font-size: 20px;
}

.upload img.logo {
    height: 60px;
    width: 280px;
}

.upload button {
    background: #cc3333;
    color: #fff;
    font-size: 20px;
    border-width: .5px;
    padding: 5px 20px;
    margin-top: 45px;
    margin-right: 80px;
    border-radius: 18px;
    display: inline-block;
    font-weight: 500;
}

.upload .disclaimer {
    box-sizing: border-box;
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    max-width: 900px;
    min-width: 900px;
    min-height: 160px;
    max-height: 160px;
    padding: 32px;
    border-radius: 20px;
    box-shadow: 0px 0px 32px #00000010;
}

.upload .disclaimer img {
    display: inline-block;
    margin-right: 32px;
}

.upload .disclaimer .text {
    margin-top: 8px;
    font-size: 20px;
    text-align: left;
    display: inline-block;
    vertical-align: top;
}

.upload select {
    margin-top: 0 !important;
    margin-left: 80px;
    height: 30px;
    width: 200px;
    color: #094531;
    background-color: #bebebe50;
    font-size: 1em;
    vertical-align: middle;
    text-align: center;
    min-width: 330px;
    padding: 3px 20px;
    font-size: 20px;
    min-height: 36px;
    border-radius: 18px;
}