:root {
    --lane-width: 910px;
    --lane-height: 60px;
    --alt-lane: #fff;
}

.stacked-bars-2 {
    width: 910px;
    height: 60px;
    min-width: 910px;
    max-width: 910px;
    min-height: 60px;
    max-height: 60px;
    border-left: 1px solid var(--muted);
    white-space: nowrap;
}

.stacked-bars-2 .bar {
    display: inline-block;
    padding: 8px;
    position: relative;
    white-space: nowrap;
    font-weight: 700;
    font-size: 18px;
    color: #fff;
    border-radius: 5px;
}

.stacked-bars-2 .bar .bar-caption {
    text-overflow: ellipsis;
    overflow: hidden;
}