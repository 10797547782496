.how-it-works {
    margin: 50px;
    position: relative;
}

@keyframes fade-in {
    to {
        opacity: .7;
    }
}

@keyframes fade-in-completely {
    to {
        opacity: 1;
        border-color: #fff;
    }
}

@keyframes move-100-down {
    to {
        transform: translateY(50px);
    }
}

@keyframes move-200-down {
    to {
        transform: translateY(100px);
    }
}

@keyframes w-50 {
    to {
        width: 50px;
        background-color: green;
        opacity: 1;
        border-color: #fff;
    }
}

@keyframes w-850 {
    to {
        opacity: 1;
        border-color: #fff;
        width: 850px;
    }
}

@keyframes w-800 {
    to {
        opacity: 1;
        border-color: #fff;
        width: 800px;
    }
}

@keyframes w-750 {
    to {
        opacity: 1;
        border-color: #fff;
        width: 750px;
    }
}

@keyframes w-700 {
    to {
        opacity: 1;
        border-color: #fff;
        width: 700px;
    }
}

@keyframes w-650 {
    to {
        border-color: #fff;
        opacity: 1;
        width: 650px;
    }
}

@keyframes w-600 {
    to {
        border-color: #fff;
        opacity: 1;
        width: 600px;
    }
}

@keyframes w-550 {
    to {
        border-color: #fff;
        opacity: 1;
        width: 550px;
    }
}

@keyframes w-500 {
    to {
        border-color: #fff;
        opacity: 1;
        width: 500px;
    }
}

@keyframes w-450 {
    to {
        border-color: #fff;
        opacity: 1;
        width: 450px;
    }
}

@keyframes w-400 {
    to {
        opacity: 1;
        border-color: #fff;
        width: 400px;
    }
}

@keyframes w-350 {
    to {
        width: 350px;
        border-color: #fff;
        opacity: 1;
    }
}

@keyframes w-300 {
    to {
        border-color: #fff;
        width: 300px;
        opacity: 1;
    }
}

@keyframes w-250 {
    to {
        border-color: #fff;
        width: 250px;
        opacity: 1;
    }
}

@keyframes w-200 {
    to {
        width: 200px;
        border-color: #fff;
        opacity: 1;
    }
}

@keyframes w-150 {
    to {
        border-color: #fff;
        width: 150px;
        opacity: 1;
    }
}

@keyframes w-100 {
    to {
        border-color: #fff;
        width: 100px;
        opacity: 1;
    }
}

@keyframes x-50 {
    to {
        left: 50px;
    }
}

@keyframes x-25 {
    to {
        left: calc(50vw - 475px);
    }
}

@keyframes x-100 {
    to {
        left: 100px;
    }
}

@keyframes x-150 {
    to {
        left: 150px;
    }
}

@keyframes x-200 {
    to {
        left: 200px;
    }
}

@keyframes x-225 {
    to {
        left: calc(50vw - 275px);
    }
}

@keyframes x-250 {
    to {
        left: 250px;
    }
}

@keyframes x-300 {
    to {
        left: 300px;
    }
}

@keyframes x-350 {
    to {
        left: 350px;
    }
}

@keyframes x-400 {
    to {
        left: 400px;
    }
}

@keyframes x-450 {
    to {
        left: 450px;
    }
}

@keyframes x-500 {
    to {
        left: 500px;
    }
}

@keyframes x-550 {
    to {
        left: 550px;
    }
}

@keyframes x-600 {
    to {
        left: 600px;
    }
}

@keyframes x-650 {
    to {
        left: 650px;
    }
}

@keyframes x-700 {
    to {
        left: 700px;
    }
}

@keyframes x-750 {
    to {
        left: 750px;
    }
}

@keyframes x-800 {
    to {
        left: 800px;
    }
}

.how-it-works .wrapper {
    margin-left: -120px;
}

.illustration {
    left: calc(50vw - 500px);
    position: relative;
    animation-name: x-25, x-225;
    animation-duration: 500ms, 1000ms;
    animation-delay: 7s, 10s;
    animation-fill-mode: forwards;
}

.bar.query {
    background-color: #133349;
    animation-name: fade-in, fade-in-completely, w-850, w-800, w-750, w-700, w-650, w-600, w-550, w-500, w-450;
    animation-duration: 500ms;
    animation-delay: .5s, 5s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s, 10.6s, 10.7s;
}

.bar.stage.n-1 {
    animation-name: fade-in, move-100-down, fade-in-completely, w-250, w-200, w-150;
    animation-duration: 500ms;
    animation-delay: 1.5s, 0s, 5s, 7s, 10s, 10.1s;
}

.bar.stage.n-2 {
    animation-name: fade-in, move-100-down, x-250, x-200, x-150, w-350, w-300, w-250, w-200;
    animation-duration: 500ms;
    animation-delay: 1.6s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s;
}

.bar.stage.n-3 {
    animation-name: fade-in, move-100-down, x-650, x-600, x-550, x-500, x-450, x-400, x-350, w-150, w-100;
    animation-duration: 500ms;
    animation-delay: 1.7s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s, 10.6s, 10.7s;
}

.bar.task.n-1 {
    animation-name: fade-in, move-100-down, move-200-down, fade-in-completely, w-50;
    animation-duration: 500ms;
    animation-delay: 3.5s, 0s, 0s, 5s, 7s;
}

.bar.task.n-2 {
    animation-name: fade-in, move-100-down, move-200-down, x-50, w-50;
    animation-duration: 500ms;
    animation-delay: 3.6s, 0s, 0s, 7s, 10s;
}

.bar.task.n-3 {
    animation-name: fade-in, move-100-down, move-200-down, x-150, x-100, w-50;
    animation-duration: 500ms;
    animation-delay: 3.7s, 0s, 0s, 7s, 10s, 10.1s;
}

.bar.task.n-4 {
    animation-name: fade-in, move-100-down, move-200-down, x-250, x-200, x-150, w-50;
    animation-duration: 500ms;
    animation-delay: 3.8s, 0s, 0s, 7s, 10s, 10.1s, 10.2s;
}

.bar.task.n-5 {
    animation-name: fade-in, move-100-down, move-200-down, x-350, x-300, x-250, x-200, w-50;
    animation-duration: 500ms;
    animation-delay: 3.9s, 0s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s;
}

.bar.task.n-6 {
    animation-name: fade-in, move-100-down, move-200-down, x-450, x-400, x-350, x-300, x-250, w-50;
    animation-duration: 500ms;
    animation-delay: 4s, 0s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s;
}

.bar.task.n-7 {
    animation-name: fade-in, move-100-down, move-200-down, x-550, x-500, x-450, x-400, x-350, x-300, w-50;
    animation-duration: 500ms;
    animation-delay: 4.1s, 0s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s;
}

.bar.task.n-8 {
    animation-name: fade-in, move-100-down, move-200-down, x-650, x-600, x-550, x-500, x-450, x-400, x-350, w-50;
    animation-duration: 500ms;
    animation-delay: 4.2s, 0s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s, 10.6s;
}

.bar.task.n-9 {
    animation-name: fade-in, move-100-down, move-200-down, x-750, x-700, x-650, x-600, x-550, x-500, x-450, x-400, w-50;
    animation-duration: 500ms;
    animation-delay: 4.3s, 0s, 0s, 7s, 10s, 10.1s, 10.2s, 10.3s, 10.4s, 10.5s, 10.6s, 10.7s;
}

.line.l-1 {
    animation-delay: 1.5s;
}

.line.l-2 {
    animation-delay: 4s;
}

.line.l-3 {
    animation-delay: 7s;
}

.line.l-4 {
    animation-delay: 10s;
}

.line {
    opacity: 0;
    animation-name: fade-in-completely;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
}

.illustration .bar {
    position: absolute;
    left: 0;
    border: 1px solid var(--muted);
    height: 50px;
    opacity: 0;
    animation-fill-mode: forwards;
    padding-top: 15px;
    color: #fff;
    font-weight: 700;
}

.bar.stage {
    background-color: #577e93;
}

.bar.task {
    background-color: red;
}

.how-it-works button {
    background: #cc3333;
    color: #fff;
    font-size: 20px;
    border-width: .5px;
    padding: 5px 20px;
    margin-top: 45px;
    border-radius: 18px;
    display: inline-block;
    font-weight: 600;
}